<template>
  <div>
    <OrderList
      :orders="orders"
      :step="50"
      :length="50"
    />
  </div>
</template>

<script>

  import OrderList from "@/components/OrderList"

  export default {
    name: 'Estimates',
    components: {
      OrderList
    },
    data: () => ({

    }),
    computed: {
      orders: function() {
        return this.$store.state.orders
      }
    },
    created() {
      this.$store.dispatch('getOrders')
    }
  }
</script>
